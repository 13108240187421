import React, { useState, useEffect } from "react"
import MaquinaModal from "../components/maquinaModal"

export default function Maquina({
  src,
  title,
  generalVisible,
  setGeneralVisible,
  position,
  setPosition,
  dimensions,
  rpm,
}) {
  const [style, setStyle] = useState({
    minHeight: "15rem",
    objectFit: "cover",
  })

  const [modalVisible, setModalVisible] = useState(false)
  function onMouseEnterHandler() {
    if (!generalVisible) {
      setStyle({
        transitionProperty: "transform",
        transitionDuration: "0.5s",
        transform: "scale(1.3)",
        filter: "sepia(100%) hue-rotate(180deg) brightness(40%) saturate(600%)",
        opacity: 1,
      })
    } else {
      setStyle({
        minHeight: "15rem",
        opacity: 0,
      })
    }
  }
  function handleClick() {
    if (!generalVisible) {
      setModalVisible(true)
      setGeneralVisible(true)
    } else setModalVisible(false)
  }
  useEffect(() => {
    !modalVisible && setGeneralVisible(false)
  }, [modalVisible])
  return (
    <div>
      <div
        id={
          title.includes("Centro")
            ? "centrosdeusinagem"
            : title.includes("Fresa")
            ? "fresas"
            : title.includes("Furadeira")
            ? "furadeira"
            : title.includes("Rosqueadeira")
            ? "rosqueadeira"
            : "plaina"
        }
        className="maquina"
        onClick={() => handleClick()}
      >
        <img className="maquinaFundo" src={src} />
        <div className="maquinaMascara">
          <img
            className="maquinaImagem"
            src={src}
            style={style}
            onMouseEnter={() => onMouseEnterHandler()}
          />
          <div className={generalVisible ? "hidden" : "maquinaTitulo"}>
            <p>Saiba mais...</p>
          </div>
        </div>
      </div>
      <h4 className="mt-4 mr-3 cursor-pointer">{title}</h4>
      <MaquinaModal
        visible={modalVisible}
        setVisible={setModalVisible}
        {...{ title, position, setPosition, src, dimensions, rpm }}
      />
    </div>
  )
}
