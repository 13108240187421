import { Image } from "antd"
import React, { useState, useEffect } from "react"
import "../styles/components/modal.css"
export default function MaquinaModal({
  visible,
  setVisible,
  title,
  position,
  setPosition,
  src,
  dimensions,
  rpm,
}) {
  useEffect(() => {
    if (visible && position === undefined) {
      setPosition(`${window.scrollY}px`)
    } else if (!visible) {
      console.log("ruin")
      setPosition()
    }
  }, [visible])

  return (
    <div
      style={{ position: "fixed", justifyContent: "center" }}
      className={`${visible ? "block" : "hidden"}`}
    >
      <div className="fundo-modal" onClick={() => setVisible(false)}></div>
      <div className="corpo-modal">
        <div className="flex justify-between">
          <h2>{title}</h2>
          <i
            class="bx bx-x bx-sm"
            style={{ cursor: "pointer" }}
            onClick={() => setVisible(false)}
          ></i>
        </div>
        <hr />
        <div
          className={`grid grid-cols-1 ${
            dimensions || rpm ? "md:grid-cols-3 gap-10" : ""
          } w-full`}
        >
          <img
            src={src}
            className="md:col-span-2"
            style={{ minHeight: "10rem", maxHeight: "25rem" }}
          />
          <div>
            {dimensions && (
              <div>
                <h3>Dimensões</h3>
                <h4>{dimensions}</h4>
              </div>
            )}
            {rpm && (
              <div>
                <h3>Rotações</h3>
                <h4>{rpm}</h4>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
