import React, { useState } from "react"
import Layout from "../components/layout"
import Maquina from "../components/maquina"
import Seo from "../components/seo"
import cncModerno from "../images/gentiger.png"
import hartford1000 from "../images/HatFord - PRO 1000.jpg"
import sinatron from "../images/FRESADORA CNC - SINITRON KAO 1000.png"
import hartford1270 from "../images/hatford1270.png"
import ferramentaria from "../images/ferramentaria.png"
import universal from "../images/Fresa universal.png"
import radial from "../images/furadeira-radial.png"
import plaina from "../images/plaina.png"
import rosca from "../images/rosca.png"
import serra from "../images/serra.png"

export default function Estrutura() {
  const [generalVisible, setGeneralVisible] = useState(false)
  const [position, setPosition] = useState()

  if (typeof window !== "undefined")
    window.onscroll = () => {
      generalVisible && window.scrollTo(0, parseInt(position || "0"))
    }

  const data = [
    {
      title: "Centro de usinagem High Speed - Gentiger",
      src: cncModerno,
      dimensions: "720(X), 500(Y), 400(Z)",
      rpm: "24000 RPM",
    },
    {
      title: "Centro de usinagem CNC - Hartford Pro 1000",
      src: hartford1000,
      dimensions: "1000(X), 600(Y), 600(Z)",
      rpm: "10000 RPM",
    },
    {
      title: "Centro de usinagem CNC - Hartford 1270",
      src: hartford1270,
      dimensions: "1270(X), 635(Y), 630(Z)",
      rpm: "10000 RPM",
    },
    {
      title: "Centro de usinagem CNC - Hartford ABR 1000",
      src: sinatron,
      dimensions: "1000(X), 600(Y), 600(Z)",
      rpm: "10000 RPM",
    },
    {
      title: "Fresadora ferramentaria N5",
      src: ferramentaria,
      dimensions: "1000(X), 30(Y), 320(Z)",
      rpm: "3600 RPM",
    },
    {
      title: "Fresadora universal Huron",
      src: universal,
      dimensions: "1070(X), 570(Y), 400(Z)",
      rpm: "2066 RPM",
    },
    {
      title: "Furadeira radial N5",
      src: radial,
      dimensions: "1500 (X), 1450 (Z)",
      rpm: "2400 RPM",
    },
    {
      title: "Plaina de mesa fresadora curso",
      src: plaina,
      dimensions: "2200(X), 1000(Y), 700(Z)",
    },
    {
      title: "Rosqueadeira articulada Roscamat 400-V",
      src: rosca,
      rpm: "375 RPM",
      dimensions: "M3 - M16",
    },
    {
      title: "Serra",
      src: serra,
      dimensions: (
        <>
          <div>
            Tamanho da lâmina:<div className="mt-1"> 190mm x 250mm</div>
          </div>
          <div className="mt-4">
            Capacidade de corte:
            <div className="mt-1">RD. 250mm</div>
            <div className="mt-1">REECT. 350mm X 430mm</div>
          </div>
        </>
      ),
    },
  ]

  return (
    <Layout titlePage="Estrutura" titleMargin="45" contactButton>
      <Seo
        keywords={[`Inovação`, `Usinagem`, `Ferramentaria`]}
        title="Estrutura"
      />
      <div className="grid lg:grid-cols-3 gap-3 mt-6">
        {data.map((d, index) => (
          <Maquina
            src={d.src}
            title={d.title}
            dimensions={d.dimensions}
            rpm={d.rpm}
            {...{
              position,
              setPosition,
              generalVisible,
              setGeneralVisible,
              index,
            }}
          />
        ))}
      </div>
    </Layout>
  )
}
